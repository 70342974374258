<template>
  <div>
    <p class="landing-text-form "> 
    
      Subscribe for updates and early access to the beta release
  </p>
  </div>

  <div id="mc_embed_shell">
     

<div id="mc_embed_signup">
    <form action="https://app.us21.list-manage.com/subscribe/post?u=14d3522701c95da0e573576ab&amp;id=39049c2e58&amp;f_id=00addde6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
        <div id="mc_embed_signup_scroll">
           
            <div class="mc-field-group"><input type="email" name="EMAIL" class="required email input-info" placeholder="Email Address" id="mce-EMAIL" required="" value=""></div>
        <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display: none;"></div>
            <div class="response" id="mce-success-response" style="display: none;"></div>
        </div>
    <div aria-hidden="true" style="position: absolute; left: -5000px;">
        /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
        <input type="text" name="b_14d3522701c95da0e573576ab_39049c2e58" tabindex="-1" value="">
    </div>
        <div class="optionalParent">
            <div class="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Join the beta list">

            </div>
        </div>
    </div>
</form>
</div>
</div>


<!--<div class="email">

   <div action="" @submit.prevent="submitForm">


 

          <input class="input-info" v-model="address"  type="email" placeholder="Email Address" required >


          <button class="button-email button5-email" @click="submitForm">
  <p style="  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;

  font-size: 18px;
">Join the beta list</p>
</button>


      <div>
        <span class="error-message"  v-if="!validEmail(address) && address >'' ">
          {{error}} </span>
      </div>
    </div>
</div> -->
</template>

<script>
import icon from  '../assets/images/icon-arrow.svg'
 
export default {
    name: 'Form',
    
    props: ({ 
        Form:'',
    }),

  data: function() {
    return{
      address:'',
     icon,
     error: 'Please provide a valid email address',
    }
  },
  methods: {
    submitForm() {
      console.log()
      //set address = ""
      if(this.validEmail(this.address)){
        this.error = ""
        this.address = ""
      }
      else{
        this.error = "Please provide a valid email address"
      }
     

    },
    validEmail: function (email) {
      if (email == ""){
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

*{
    --soft-red: hsl(0, 93%, 68%);
    --desaturated-red: hsl(0, 36%, 70%);
    --Dark-Grayish-Red: hsl(0, 6%, 24%);
}
.refferal_badge{
  display: none !important;
}
.mc_embed_signup{
background: none !important;
margin: 0 !important ;
  
}
#mc_embed_signup form{
  margin: 0 !important ;
}

#mc_embed_signup .button {
  border: none;
  color: white;
  font-family: 'Josefin Sans' !important ;
  padding: 8px 16px !important ;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 16px !important;
  margin: 4px 2px !important;
  transition-duration: 0.4s !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  background-color: #F90 !important;
  line-height: 1.3;

font-size: 18px;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

#mc_embed_signup .button:hover {
  background-color: #555555;
  color: white;
}
.button-email {

  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button5-email {
  background-color: #F90;;
  color: black;
}
.button5-email:hover {
  background-color: #555555;
  color: white;
}
.email {
  position: relative;
  margin-top: 1rem;
  height: 2.5rem;
  width: 50rem;
}
.landing-text-form {
  margin-top: 2rem;
  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;
  font-size: 20px;
  font-style: italic;
  max-width: 65%;
}
.input-info {
  border-radius: 8px !important;
  border: 1px solid var(--desaturated-red) !important;
  color: var(--desaturated-red) !important;
  padding: 0.2rem 1.5rem !important;
  height: 2.4rem !important;
  width: 25rem !important;
  font-family: "Josefin Sans" !important;
  font-weight: 300 !important;
}

input::placeholder {
  color: var(--red-light);
}

input:focus,
button:focus {
  outline: none;
}

.click {
  background: linear-gradient(180deg, #F00 0%, #FDBE2B 100%);
  border: 0;
  border-radius: 2rem;
  box-shadow: 0 5px 15px 4px #F00(0, 80%, 86%);
  position: absolute;
  top: 0;
  right: 50%;
  width: 5rem;
  height: 98%;
  cursor: pointer;
  border-style: none;
  transition:0.4s ;
}

.click:hover {
  background: linear-gradient(45deg, #F00,#F00); 

}
.arrow {
   width: 10px;
   justify-content: center;
   align-content: center;
   margin: 2px 0;
}

.error-message {
  color: white;
  font-style: italic;
  padding: 20px;
  margin-top: 1rem ;
}

.icon-error {
  position: absolute;
  padding: 6px;
  margin-left: 17rem;
}


</style>