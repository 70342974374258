<template>
  <p class="landing-text"> 
    
    Exibi is your all-in-one platform for showcasing and discovering a diverse spectrum of creative hobbies, services, and experiences.
  </p>
</template>

<script>
export default {

}
</script>

<style>
.landing-text {
  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;
  font-size: 20px;
  font-style: italic;
  max-width: 65%;
}

</style>