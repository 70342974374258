<template>
<div class="header">
    <h1 class="header-font">
  
        <br>
        <span>Explore Creativity <br> Around You.</span> 
</h1>
</div>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

.header{
  padding: 2rem 0;
}

.pink-text {
color: #FFF;
font-weight: 300;
}

.header-font {
  font-family: 'Josefin Sans';
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 5px;
  font-size: 54px;;
}
</style>