<template>
  <div v-show="!mobile" id="mobile-card">
        <div class="mobile-logo">
            <img :src="Logo">
        </div>
        <div class="mobile-content">
            <h1 class="mobile-header">
                <span>Explore Creativity <br> Around You.</span> 
            </h1>
            <p class="landing-mobile"> 
                Exibi is your all-in-one platform for showcasing and discovering a diverse spectrum of creative hobbies, services, and experiences. <br><br>
                
            </p>
        </div>

        <div class="mobile-padding">
          <div id="mc_embed_shell">
     

     <div id="mc_embed_signup">
         <form action="https://app.us21.list-manage.com/subscribe/post?u=14d3522701c95da0e573576ab&amp;id=39049c2e58&amp;f_id=00addde6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
             <div id="mc_embed_signup_scroll">
                
                 <div class="mc-field-group"><input type="email" name="EMAIL" class="required email input-info" placeholder="Email Address" id="mce-EMAIL" required="" value=""></div>
             <div id="mce-responses" class="clear foot">
                 <div class="response" id="mce-error-response" style="display: none;"></div>
                 <div class="response" id="mce-success-response" style="display: none;"></div>
             </div>
         <div aria-hidden="true" style="position: absolute; left: -5000px;">
             /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
             <input type="text" name="b_14d3522701c95da0e573576ab_39049c2e58" tabindex="-1" value="">
         </div>
             <div class="optionalParent">
                 <div class="clear foot">
                     <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Join the beta list">
     
                 </div>
             </div>
         </div>
     </form>
     </div>
     </div>
           <!-- <div  id="mobile-address">
              

               <input class="mobile-input" v-model="address" type="email" placeholder="Email Address" required>
               <button class="button-mobile-submit button5-email-mobile " @click="submitForm">
  <p style="  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;

  font-size: 14px;
">Join the beta list</p>
</button>

</div> -->
        </div>
        <div  class="mobile-Img">
            <img :src="mobileImg" >
        </div>
<div style="justify-content: center; display: flex; margin-top: -15px; margin-bottom: 20px;">
    <button class="button-email-mobile button5-email-mobile " @click="setShowMore">
  <p style="  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;
  font-size: 14px;
">{{!showMoreValue ? "Show More" : "Show Less"}}</p>
</button>
</div>

<div v-if="showMoreValue" style="display: flex; flex-direction: column; height: 100%; width: 100%; ">
    <div class="segmented-control">
    <button
      v-for="(segment, index) in segments"
      :key="index"
      :class="{ 'active': activeSegment === index }"
      @click="setActiveSegment(index)"
    >
    <p  :class="{ 'active': activeSegment === index }" style="  color: #6C6C6C;
 font-family: 'Josefin Sans';
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;">
      {{ segment }}
    </p>
 
    </button>
  </div>
  <div v-if="activeSegment === 0" style="margin: 2rem 1rem;">
  <div class="content-item">
    <p class="header-class">
      Define your craft.
    </p>
    <p class="header-red">
      Choose your categories
    </p>
    <p class="header-tex">
      Tailor your Exibi profile by selecting the creative categories that best represent your craft. Whether you're a photographer, painter, or a master of multiple talents, Exibi caters to your unique artistic journey.
    </p>
    <p class="header-red">
      Keyword Magic
    </p>
    <p class="header-tex">
      Enhance your discoverability by adding relevant keywords to your posts. When users search for those keywords, your work will shine in the spotlight, making it easier for others to find and appreciate your creative genius.
    </p>
    <img src="../assets/images/wizard.png" class="item-image" alt="Wizard Image"/>
  </div>

  <div class="content-item">
    <p class="header-class">
      Curate your Portfolio.
    </p>
    <p class="header-red">
      Your best work on display
    </p>
    <p class="header-tex">
      Leverage the Exibi showcase feature to elevate your work into the spotlight, transforming views into a loyal following and potential clients
    </p>
    <p class="header-red">
      Organization made simple
    </p>
    <p class="header-tex">
      Say goodbye to the chaos of scattered work. With Exibi’s collection feature, you have full control over how you organize your portfolio. Arrange your projects and themes effortlessly, ensuring they’re always ready to showcase at a moment’s notice.
    </p>
    <img src="../assets/images/ongo.png" class="item-image" alt="Ongo Image"/>
  </div>

  <div class="content-item">
    <p class="header-class">
      Connect with your audience.
    </p>
    <p class="header-red">
      Keep your followers in the loop
    </p>
    <p class="header-tex">
      Stay connected with your audience with text-posts on Exibi. Whether it’s a fresh project launch, a limited-time offer, or just expressing your gratitude, use text-posts to keep your followers informed and engaged
    </p>
    <img src="../assets/images/announcement.png" class="item-image" alt="Announcement Image"/>
  </div>
</div>

<div v-if="activeSegment !== 0" style="margin: 2rem 1rem;">
  <div class="content-item">
   
    <p class="header-class">
      Your personal gallery.
    </p>
    <p class="header-red">
      Choose your interests
    </p>
    <p class="header-tex">
      Exibi features hundreds of creative categories and subcategories. Prefer to focus on what interests you the most? It’s effortless — simply follow your favorites and tailor your feed to your preferences.
    </p>
    <p class="header-red">
      Stay connected
    </p>
    <p class="header-tex">
      You’ll come across plenty of talented creators on the platform. Follow your favorites — stay updated with their latest work, receive important updates, and join them on their creative journey.
    </p>
    <img src="../assets/images/gallery.png" class="item-image" alt="Gallery Image"/>
  </div>

  <div class="content-item">
    <p class="header-class">
      Discover like never before.
    </p>
    <p class="header-red">
      Broaden your creative horizons
    </p>
    <p class="header-tex">
      Never run out of creative discoveries with Exibi. We’ve built a platform that keeps the excitement alive.
      <br><br>
      Explore the latest trends on the platform, immerse yourself in The Exhibit to hear the story of creators, or simply venture into new categories.. There’s always something new waiting for you on Exibi.
    </p>
    <img src="../assets/images/search.png" class="item-image" alt="Search Image"/>
  </div>

  <div class="content-item">
    <p class="header-class">
      Be in control.
    </p>
    <p class="header-red">
      Find exactly what you’re looking for
    </p>
    <p class="header-tex">
      Streamlining your discovery experience is our goal. We know how it feels to know exactly what you want but face challenges in finding it.
      <br><br>
      That’s where Exibi comes in to assist. We empower you with the tools to fine-tune your search, allowing you to be as broad or specific as you like. Whether you’re seeking specific content types, styles, or even location-based results, Exibi ensures you remain firmly in control.
    </p>
    <img src="../assets/images/map.png" class="item-image" alt="Map Image"/>

  </div>
</div>




  </div>    


  </div>
</template>

<script>
import Logo from '../assets/images/logo.svg'
import mobileImg from '../assets/images/hero-desktop.png'

import arrow from '../assets/images/icon-arrow.svg'
export default {
name: 'MobileView',
props: '',

data: function() {
return{
    Logo,
    mobileImg,
    arrow,
    address:'',
    error: 'Please provide a valid email address',
    segments: ['Showcase on Exibi', 'Explore with Exibi'],
    activeSegment: 0,
    showMoreValue: false,
}
},
methods: {
    setActiveSegment(index) {
      this.activeSegment = index;
      // Do something based on the selected segment
    },
    setShowMore() {
      this.showMoreValue = !this.showMoreValue;
    
    },
    submitForm() {

      this.address = ''
    },
    validEmail: function (email) {
      if (email == ""){
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
.segmented-control button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/**
get last button and add border radius to the right
*
*/
#mc_embed_signup .mc-field-group input {
    display: block;
    width: 90% !important;
    padding: 8px 0;
    text-indent: 2%;
}
.content-item {
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
  }

  .item-image {
    align-self: center;
    width: 100%;
    max-width: 502px;
    max-width: 502px;
    height: auto;
    display: block;
  }
.segmented-control button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.segmented-control button.active {
  background-color: #F90;
  color: white;
}

p.active{
  color: #000 !important;
}
.segmented-control button {

padding: 10px 20px;
border: 0px solid #ccc;
background-color: #f9f9f9;
cursor: pointer;
}

.segmented-control {
  display: flex;
justify-content: center;
margin-top: 10px;
 
}

.button-mobile-submit {

border: none;
color: white;
padding: 0.1rem 1.5rem;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
height: 3rem;
transition-duration: 0.4s;
cursor: pointer;
border-radius: 8px;
border-bottom-left-radius: 0px;
border-top-left-radius: 0px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button-email-mobile {

border: none;
color: white;
padding: 0.1rem 1.5rem;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
height: 3rem;
transition-duration: 0.4s;
cursor: pointer;
border-radius: 8px;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button5-email-mobile {
background-color: #F90;;
color: black;
}
.button5-email-mobile:hover {
background-color: #555555;
color: white;
}
#mobile-card {
    background: linear-gradient(180deg, #F00 0%, #FDBE2B 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.mobile-logo {
    padding: 30px 0 30px 30px;
}

.mobile-Img {
    max-width: 100%;
    height: 100%;
}

.mobile-Img img {
    width: fill;
    padding-bottom: 30px;
}

.mobile-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  margin-left: 1rem;
}

.mobile-header {
    text-transform: uppercase;
    font-family: 'Josefin Sans';
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 5px;
    padding: 10px 25px;
}

.header-coloured{
    color: #FFF;
    line-height: 1.3;
    font-weight: 300;
}


.dark-color {
    color: hsl(0, 6%, 24%);
}

.landing-mobile{
    padding: 10px 25px;
    text-decoration: none;
    color: #FFF;
    line-height: 1.4;
    font-size: 16px;
    font-family: 'Josefin Sans';
    font-weight: 400;
}

.mobile-input {
  border-radius: 8px;
    border: 1px solid var(--desaturated-red);
    color: var(--desaturated-red);
    padding: 0.1rem 1.5rem;
    height: 3rem;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 18rem;
    font-family: "Josefin Sans";
    font-weight: 300;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.mobile-padding {
    padding: 0px 25px;
    padding-bottom: 25px;
    position: relative;
}

.mobile-btn {
    background: linear-gradient(180deg, #F00 0%, #FDBE2B 100%);
    border-radius: 2rem;
    box-shadow: 0 5px 20px 5px hsl(0, 80%, 86%);
    width: 4rem;
    height: 3rem;
    cursor: pointer;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
 
}

.mobile-btn:hover {
    background: linear-gradient(45deg, #F00 0%, #FDBE2B 100%);
}

#btn-small-flex{
   margin: 0 auto;
   display: flex;
    justify-content: center;
    align-items: center;
}

#mobile-address{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-error {
  position: absolute;
  padding: 6px;
  margin-left: 17rem;
}

.error-message {
  color: white;
  font-style: italic;
  padding: 20px;
  margin-top: 1rem ;
}
</style>