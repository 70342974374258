<template>
  <div style="display: flex; flex-direction: column; height: 100%; width: 100%; ">
    <div class="segmented-control">
    <button
      v-for="(segment, index) in segments"
      :key="index"
      :class="{ 'active': activeSegment === index }"
      @click="setActiveSegment(index)"
    >
    <p  :class="{ 'active': activeSegment === index }" style="  color: #6C6C6C;
 font-family: 'Josefin Sans';
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;">
      {{ segment }}
    </p>
 
    </button>
  </div>

  <div v-if="activeSegment === 0" style="margin-top: 2rem; margin-left: 5rem; margin-right: 5rem;">
    <div class="row">
    <div class="column">
      <p class="header-class">
        Define your craft.
      </p>
      <p class="header-red">
        Choose your catagories
      </p>
      <p class="header-tex">
        Tailor your Exibi profile by selecting the creative categories that best represent your craft. Whether you're a photographer, painter, or a master of multiple talents, Exibi caters to your unique artistic journey.
      </p>
      <p class="header-red">
        Keyword Magic
      </p>
      <p class="header-tex">
        Enhance your discoverability by adding relevant keywords to your posts. When users search for those keywords, your work will shine in the spotlight, making it easier for others to find and appreciate your creative genius.
      </p>
    </div>
    <div class="column-img">
    <img src="../assets/images/wizard.png" style=" width: 502px;
height: 502px;
flex-shrink: 0;"/>
    </div>

    
  </div>

  <div class="row">
    <div class="column-img">
    <img src="../assets/images/ongo.png" style=" width: 502px;
height: 502px;
flex-shrink: 0;"/>
    </div>
    <div class="column">
      <p class="header-class">
        Curate your Porfolio.
      </p>
      <p class="header-red">
        Your best work on display
      </p>
      <p class="header-tex">
        Leverage the Exibi showcase feature to elevate your work into the spotlight, transforming views into a loyal following and potential clients
      </p>
      <p class="header-red">
        Organization made simple
      </p>
      <p class="header-tex">
        Say goodbye to the chaos of scattered work. With Exibi’s collection feature, you have full control over how you organize your portfolio. Arrange your projects and themes effortlessly, ensuring they’re always ready to showcase at a moment’s notice.
      </p>
    </div>


    
  </div>

  <div class="row">
    <div class="column">
      <p class="header-class">
        Connect with your audience.
      </p>
      <p class="header-red">
        Keep your followers in the loop
      </p>
      <p class="header-tex">
        Stay connected with your audience with text-posts on Exibi. Whether it’s a fresh project launch, a limited-time offer, or just expressing your gratitude, use text-posts to keep your followers informed and engaged
      </p>
 
    </div>
    <div class="column-img">
    <img src="../assets/images/announcement.png" style=" width: 502px;
height: 502px;
flex-shrink: 0;"/>
    </div>

    
  </div>
  </div>


  <div v-if="activeSegment !== 0" style="margin-top: 2rem; margin-left: 5rem; margin-right: 5rem;">
    <div class="row">
      <div class="column-img">
    <img src="../assets/images/gallery.png" style=" width: 502px;
height: 502px;
flex-shrink: 0;"/>
    </div>
    <div class="column">
      <p class="header-class">
        Your personal gallery.
      </p>
      <p class="header-red">
        Choose your interests
      </p>
      <p class="header-tex">
        Exibi features hundreds of creative categories and subcategories. Prefer to focus on what interests you the most? It’s effortless — simply follow your favorites and tailor your feed to your preferences.
      </p>
      <p class="header-red">
        Stay connected
      </p>
      <p class="header-tex">
        You’ll come across plenty of talented creators on the platform. Follow your favorites — stay updated with their latest work, receive important updates, and join them on their creative journey.
      </p>
    </div>
 

    
  </div>

  <div class="row">

    <div class="column">
      <p class="header-class">
        Discover like never before.
      </p>
      <p class="header-red">
        Broaden your creative horizons
      </p>
      <p class="header-tex">
        Never run out of creative discoveries with Exibi. We’ve built a platform that keeps the excitement alive. <br> <br>

Explore the latest trends on the platform, immerse yourself in The Exhibit to hear the story of creators, or simply venture into new categories.. There’s always something new waiting for you on Exibi.
      </p>
 
    </div>
    <div class="column-img">
    <img src="../assets/images/search.png" style=" width: 502px;
height: 502px;
flex-shrink: 0;"/>
    </div>

    
  </div>

  <div class="row">

    <div class="column-img">
    <img src="../assets/images/map.png" style=" width: 502px;
    height: 502px;
    flex-shrink: 0;"/>
    </div>
    <div class="column">
      <p class="header-class">
        Be in control.
      </p>
      <p class="header-red">
        Find exactly what you’re looking for
      </p>
      <p class="header-tex">
        Streamlining your discovery experience is our goal. We know how it feels to know exactly what you want but face challenges in finding it. 
<br><br>
That’s where Exibi comes in to assist. We empower you with the tools to fine-tune your search, allowing you to be as broad or specific as you like. Whether you’re seeking specific content types, styles, or even location-based results, Exibi ensures you remain firmly in control.
      </p>
 
    </div>
    
  </div>
  </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      segments: ['Showcase on Exibi', 'Explore with Exibi'],
      activeSegment: 0
    };
  },
  methods: {
    setActiveSegment(index) {
      this.activeSegment = index;
      // Do something based on the selected segment
    }
  }
};
</script>

<style>

.segmented-control {
  display: flex;
justify-content: center;
 
}


.row {
      display: flex;
      margin-bottom: 20px;
    }

    .column {
      flex: 1;
      padding: 10px;

      margin: 0 5px;
    }

    .column-img{
      flex: 1;
      padding: 10px;

      margin: 0 5px;
    }

.segmented-control button {

  padding: 10px 20px;
  border: 0px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}
/**
get first button and add border radius to the left
*
*/
.header-class{
  color: #1C1C1C;
  font-family: 'Josefin Sans';
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.header-red{
  margin-top: 1rem;
  color: #6C6C6C;
  font-family: 'Josefin Sans';

font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.header-tex{
  margin-top: .5rem;
  color: #000;
  font-family: 'Josefin Sans';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.segmented-control button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/**
get last button and add border radius to the right
*
*/

.segmented-control button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.segmented-control button.active {
  background-color: #F90;
  color: white;
}

p.active{
  color: #000 !important;
}

</style>
