<template>
    <mobile-view id="mobile-view"/>
    <div class="parent-wrapper" id="desktop-view">
      <div class="wrapper" >
      <div style="
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-top: 0 !important;
  min-width: 30%;
  max-width: 50%;
  margin-top: 1rem;
  ">
        <div class="logo-box">
        <Logo />
      </div>
    <div class="card">

      <Welcome />
      <LandingText />
      <email-form />
  </div>
 
      </div>
     

  <div>
    <hero-cover-img />
  </div>

  </div>
  <div style="display: flex;
    justify-content: center; margin-top: 2rem; ">

<button class="button button5" @click="showMore()">
  <p style="  font-family: 'Josefin Sans';
  color: #FFF;
  line-height: 1.3;

  font-size: 20px;
">{{!showMoreValue ? "Show More" : "Show Less"}}</p>
</button>

  </div>
  <div  v-if="showMoreValue" style="  margin-top: 2rem;   display: flex;
    justify-content: center;">

    <SegmentControll/>
  </div>



    </div>


</template>

<script>
import Logo from './components/Logo.vue'
import Welcome from './components/Welcome.vue'
import LandingText from './components/LandingText.vue'
import HeroCoverImg from './components/HeroCoverImg.vue'
import EmailForm from './components/EmailForm.vue'
import MobileView from './components/MobileView.vue'
import SegmentControll from './components/SegmentControll.vue'

export default {
  name: 'App',

  components: {
    Logo,
    Welcome,
    LandingText,
    HeroCoverImg,
    EmailForm,
    MobileView,
    SegmentControll
  },
  data(){
    return{
      showMoreValue: false,
    }
  
  },
  mounted(){
    window.addEventListener('resize', this.handleResize);
    const img = new Image();
    img.src = '../src/assets/images/hero-desktop.png';
    img.onload = () => {
        // Image has been preloaded
        console.log('Image preloaded successfully');
    };
  },
  methods: {
    showMore(){
      if(this.showMoreValue == false){
        document.getElementById("desktop-view").classList.remove("parent-wrapper");
 
      }else{
        //remove the height
        document.getElementById("desktop-view").classList.add("parent-wrapper");
      }
  
      this.showMoreValue = !this.showMoreValue
    }
  }
  
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 
}
html, body {
  height: 100%;
}
#app{
  height: 100%;
}
#desktop-view{
  background: linear-gradient(180deg, #F00 0%, #FDBE2B 100%);
}
.parent-wrapper{

  height:100%
}
.wrapper {
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;




  overflow: hidden;
  
}

.card{


  display: flex;
  flex-direction: column;

  padding-top: 0 !important;

}

#mobile-view {
  display:none;
}

.attribution {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.attribution a {
  color: hsl(228, 45%, 44%);
}

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;

box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.button5 {
  background-color: #F90;;
  color: black;
 
}

.button5:hover {
  background-color: #555555;
  color: white;
}

@media screen and (max-width: 950px){
  
  #desktop-view {
    display: none;
}
#mobile-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(180deg, #F00 0%, #FDBE2B 100%);
  
}

.mobile-btn{
  position: absolute;
  top: 21%;
  right: 15%;
}

.mobile-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}

#btn-padding {
    display: flex;
    align-items: center;
    justify-content: center;
}
}
</style>
